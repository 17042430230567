.box {
  background-color: $color-white;
  border: 1px solid $color-gray-light;
  border-radius: 3px;
  display: flex;
  margin: $spacer-3 0;
}

.box__content {
  flex: 1 0 0;
  min-width: 380px;
  padding: $spacer-4;
}