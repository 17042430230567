@import '@cmsgov/design-system-support/src/settings/index';

.ds-c-label {
  display: block;
  font-size: $base-font-size;
  font-weight: $font-bold;
  margin-bottom: 0;
  margin-top: $spacer-3;
  max-width: $input-max-width;
  // Remove default padding on <legend> elements:
  padding: 0;
}

.ds-c-label--inverse {
  color: $color-base-inverse;
}

// Applies to "Checkbox & Radio" and "Date field"
.ds-c-fieldset {
  border: 0;
  // We apply the same margin as a label, since in the context of a fieldset
  // this would be a <legend>. See .ds-c-fieldset > .ds-c-label for more info.
  margin: $spacer-3 0 0;
  min-width: 0;
  padding: 0;
}

// Webkit won't render a top margin on a fieldset's <legend>, so we apply
// the top margin to the fieldset instead.
.ds-c-fieldset > .ds-c-label:first-child {
  margin-top: 0;
}

.ds-c-field__hint {
  color: $color-muted;
  display: block;
  font-weight: $font-normal;
}

.ds-c-field__hint--inverse {
  color: $color-muted-inverse;
}

.ds-c-choice + label .ds-c-field__hint {
  flex-basis: 100%;
}
