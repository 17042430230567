/* stylelint-disable declaration-no-important */
@import '@cmsgov/design-system-support/src/settings/index';

.ds-u-font-weight--normal {
  font-weight: $font-normal !important;
}

.ds-u-font-weight--semibold {
  font-weight: $font-semibold !important;
}

.ds-u-font-weight--bold {
  font-weight: $font-bold !important;
}
