@import '@cmsgov/design-system-support/src/settings/index';

.ds-c-field-mask {
  position: relative;
}

.ds-c-field__before {
  bottom: $input-padding;
  left: $input-padding;
  position: absolute;
  top: $input-padding;
}

.ds-c-field__before--currency {
  // Increase currency size so it serves more as an icon and
  // better aligns with the input value text
  font-size: $base-font-size + 2;
  font-weight: $font-bold;
  // Adjust to better align icon with value's baseline
  padding-top: 1px;
}

.ds-c-field--currency {
  // Normal padding + space for the icon + normal padding
  padding-left: $input-padding + 4px + $input-padding;
}
