/* stylelint-disable declaration-no-important */
@import '@cmsgov/design-system-support/src/settings/index';

// primary
.ds-u-color--primary:not(:focus) {
  color: $color-primary !important;
}

.ds-u-color--primary-darker:not(:focus) {
  color: $color-primary-darker !important;
}

.ds-u-color--primary-darkest:not(:focus) {
  color: $color-primary-darkest !important;
}

// primary alt
.ds-u-color--primary-alt:not(:focus) {
  color: $color-primary-alt !important;
}

.ds-u-color--primary-alt-light:not(:focus) {
  color: $color-primary-alt-light !important;
}

.ds-u-color--primary-alt-lightest:not(:focus) {
  color: $color-primary-alt-lightest !important;
}

.ds-u-color--primary-alt-dark:not(:focus) {
  color: $color-primary-alt-dark !important;
}

.ds-u-color--primary-alt-darkest:not(:focus) {
  color: $color-primary-alt-darkest !important;
}

// gray
.ds-u-color--gray:not(:focus) {
  color: $color-gray !important;
}

.ds-u-color--gray-lightest:not(:focus) {
  color: $color-gray-lightest !important;
}

.ds-u-color--gray-lighter:not(:focus) {
  color: $color-gray-lighter !important;
}

.ds-u-color--gray-light:not(:focus) {
  color: $color-gray-light !important;
}

.ds-u-color--muted:not(:focus) {
  color: $color-muted !important;
}

// states
.ds-u-color--error:not(:focus) {
  color: $color-error !important;
}

.ds-u-color--error-dark:not(:focus) {
  color: $color-error-dark !important;
}

.ds-u-color--error-darkest:not(:focus) {
  color: $color-error-darkest !important;
}

.ds-u-color--error-light:not(:focus) {
  color: $color-error-light !important;
}

.ds-u-color--success:not(:focus) {
  color: $color-success !important;
}

// base colors
.ds-u-color--base:not(:focus) {
  color: $color-base !important;
}

.ds-u-color--base-inverse:not(:focus) {
  color: $color-base-inverse !important;
}

.ds-u-color--muted-inverse:not(:focus) {
  color: $color-muted-inverse !important;
}

.ds-u-color--black:not(:focus) {
  color: $color-black !important;
}

.ds-u-color--white:not(:focus) {
  color: $color-white !important;
}

.ds-u-color--inherit:not(:focus) {
  color: inherit !important;
}
