/* stylelint-disable declaration-no-important */
@import '@cmsgov/design-system-support/src/settings/index';

.ds-u-valign--top {
  vertical-align: top !important;
}

.ds-u-valign--middle {
  vertical-align: middle !important;
}

.ds-u-valign--bottom {
  vertical-align: bottom !important;
}
