@import '@cmsgov/design-system-support/src/index';

@mixin spinner-size($inner-size, $outer-size) {
  height: $outer-size;
  width: $outer-size;
  $padding: (($outer-size - $inner-size) / 2);

  &::before,
  &::after {
    border-width: ($inner-size / 8);
    height: $inner-size;
    left: $padding;
    top: $padding;
    width: $inner-size;
  }
}

.ds-c-spinner {
  @include spinner-size($spacer-4, $spacer-4);
  box-sizing: border-box;
  display: inline-block;
  position: relative;

  &::before,
  &::after {
    border-radius: 50%;
    border-style: solid;
    box-sizing: border-box;
    content: '';
    display: block;
    left: 0;
    position: absolute;
    top: 0;
  }

  &::before {
    border-color: inherit;
    opacity: 0.2;
  }

  &::after {
    animation: spin $animation-speed-4 infinite linear;
    border-bottom-color: transparent;
    border-left-color: inherit;
    border-right-color: transparent;
    border-top-color: transparent;
    transform: translateZ(0);
  }

  .ds-c-button > & {
    @include inline-icon;
    @include spinner-size(1em, 1em);
  }
}

.ds-c-spinner--filled {
  @include spinner-size($spacer-4, $spacer-6);
  background-color: $color-background;
  border-radius: 50%;
  color: $color-base;
  height: $spacer-6;
  width: $spacer-6;
}

.ds-c-spinner--small {
  @include spinner-size($spacer-2, $spacer-2);

  &.ds-c-spinner--filled {
    @include spinner-size($spacer-2, $spacer-3);
  }
}

.ds-c-spinner--big {
  @include spinner-size($spacer-5, $spacer-5);

  &.ds-c-spinner--filled {
    @include spinner-size($spacer-5, $spacer-7);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
