/* stylelint-disable selector-class-pattern */
@import '@cmsgov/design-system-support/src/settings/index';

%ds-base {
  font-family: $font-sans;
  font-size: $base-font-size;
  line-height: $base-line-height;
}

.ds-base {
  @extend %ds-base;
  color: $color-base;
}

.ds-base--inverse {
  @extend %ds-base;
  background-color: $color-background-inverse;
  color: $color-base-inverse;
}
/* stylelint-enable */
