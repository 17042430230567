.site-logo,
.site-logo:visited {
  align-items: center;
  color: $color-primary-darkest;
  display: flex;
  flex-shrink: 0;
  font-size: $h5-font-size;
  font-weight: $font-bold;
  text-decoration: none;

  @media (min-width: $width-sm) {
    font-size: $h3-font-size;
  }

  img {
    margin-right: $spacer-1;
    width: $spacer-7;

    @media (min-width: $width-sm) {
      width: $spacer-7;
    }
  }
}

.site-logo-text{
  width: 88px;
}

.site-logo-text:hover {
  color: $color-primary;
}

.navbar {
  align-items: center;
  background-color: #fff;
  display: flex;
  // justify-content: space-between;
  padding: $spacer-2;
  position: relative;

  @media (min-width: $width-sm) {
    padding-left: $spacer-4;
    padding-right: $spacer-4;
  }
}

.navbar-brand {
  padding-right: 10rem;
}

.topnav-wrap {
  display: none;
  flex-direction: column;

  &.is-visible {
    animation: slidein-left 0.3s ease-in-out;
    background-color: $color-white;
    bottom: 0;
    display: flex;
    position: fixed;
    height: 100%;
    max-width: 360px;
    right: 0;
    top: 0;
    transform: translateX(0);
    width: 75%;
    z-index: $z-index-flyout;

    @media (min-width: $width-nav) {
      background-color: transparent;
      display: block;
      position: static;
      height: auto;
      max-width: unset;
      width: auto;
    }
  }

  @media (min-width: $width-nav) {
    background-color: transparent;
    display: block;
    position: static;
    height: auto;
    width: auto;
  }
}

.topnav {
  display: flex;
  flex-direction: column;
  list-style: none;

  @media (min-width: $width-nav) {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

.topnav__show-button {
  padding: $spacer-1 $spacer-3 $spacer-1 $spacer-3;
  transform: translateX($spacer-3);
  width: auto;
  margin-left:auto; margin-right:0;

  @media (min-width: $width-nav) {
    display: none;
  }
}

.topnav__close-button {
  align-self: flex-end;
  color: $color-gray;
  width: auto;

  @media (min-width: $width-nav) {
    display: none;
  }
}

.topnav__close-button:hover,
.topnav__close-button:focus {
  color: $color-gray-dark;
}

.topnav__item {
  border-bottom: 1px solid $color-gray-lighter;

  &:last-child {
    border: none;
    margin-top: $spacer-2;
    padding-right: 0;

    @media (min-width: $width-nav) {
      margin-top: 0;
    }
  }

  @media (min-width: $width-nav) {
    border: none;
  }
}

.topnav__item--buttons {
  border: 0;
  margin-top: $spacer-3;

  @media (min-width: $width-nav) {
    margin-top: 0;
  }
}

.topnav__link,
.topnav__link:visited {
  border-bottom: 2px solid $color-white;
  color: $color-primary-darkest;
  display: block;
  font-size: $h4-font-size;
  font-weight: $font-bold;
  padding: $spacer-2 0;
  text-decoration: none;

  @media (min-width: $width-nav) {
    display: inline-block;
    padding: $spacer-1 $spacer-1;
    margin-right: $spacer-2;
  }
}

.site-overlay {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0,0,0, .5);
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: $z-index-overlay;
  opacity: 0;
  visibility: hidden;
  transition: opacity .2s ease-in-out;

  &.is-visible {
    opacity: 1;
    visibility: visible;
  }

  @media (min-width: $width-nav) {
    display: none;
  }
}

.topnav__link:hover {
  border-bottom: 2px solid $color-primary;
  color: $color-primary;
}


.topnav__dropdown {
  list-style: none;

  @media (min-width: $width-nav) {
    background-color: $color-primary-darker;
    position: absolute;
    right: 0;
    width: 100%;
    z-index: $z-index-nav;
  }
}

.topnav__dropdown__link {
  display: block;
  color: $color-base;
  font-size: $h5-font-size;
  font-weight: $font-bold;
  text-decoration: none;

  @media (min-width: $width-nav) {
    color: $color-white;
  }
}

.topnav__dropdown__link:hover {
  @media (min-width: $width-nav) {
    color: $color-primary-alt-lightest;
    text-decoration: underline;
  }
}

.topnav__dropdown__link:visited {
  color: $color-white;
}

// Overrides for accorion functionality
.topnav__item.usa-accordion {
  position: relative;
}

.topnav__link.usa-accordion__button {
  background-color: transparent;

  @media (min-width: $width-nav) {
    background-image: url(angle-arrow-down.svg);
    background-size: .7rem;
    margin-right: 0;
    padding-right: $spacer-5;
  }
}

.topnav__link.usa-accordion__button[aria-expanded="true"] {

  @media (min-width: $width-nav) {
    background-color: $color-primary-darker;
    background-image: url(angle-arrow-up-white.svg);
    color: $color-white;
  }
}


// Animations
@keyframes slidein-left {
  from {
    transform: translateX(360px);
  }

  to {
    transform: translateX(0);
  }
}