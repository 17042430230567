/* stylelint-disable declaration-no-important */
@import '@cmsgov/design-system-support/src/settings/index';

// TODO: Example border radius utilities and make utility format consistent
.ds-u-radius {
  border-radius: $border-radius;
}

.ds-u-radius--pill {
  border-radius: $border-radius-pill;
}

.ds-u-radius--circle {
  border-radius: $border-radius-circle;
}
