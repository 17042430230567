@import '@cmsgov/design-system-support/src/settings/index';

.ds-c-badge {
  background-color: $color-gray;
  border-radius: $border-radius-pill;
  color: $color-white;
  display: inline-block;
  font-size: $small-font-size;
  line-height: $heading-line-height;
  margin-right: $spacer-half;
  padding: $spacer-half $spacer-1;

  &:only-of-type {
    margin-right: 0;
  }
}

.ds-c-badge--info {
  background-color: $color-primary;
}

.ds-c-badge--success {
  background-color: $color-success;
}

.ds-c-badge--warn {
  background-color: $color-warn;
  color: $color-base;
}

.ds-c-badge--alert {
  background-color: $color-error;
}
