.usa-accordion {
  list-style-type: none;
  margin: 0;
  padding: 0;
  width: 100%;
}

.usa-accordion > li {
  margin-bottom: 0;
  max-width: unset;
}

.usa-accordion > ul li ul {
  list-style: disc;
}

.usa-accordion > ul li ul > li > ul {
  list-style: circle;
}

.usa-accordion > ul li ul > li > ul > li > ul {
  list-style: square;
}

.usa-accordion + .usa-accordion,
.usa-accordion + .usa-accordion--bordered{
  margin-top: 0.5rem;
}

.usa-accordion--bordered .usa-accordion__content {
  border-bottom: 0.25rem solid $color-warn-lightest;
  border-left: 0.25rem solid $color-warn-lightest;
  border-right: 0.25rem solid $color-warn-lightest;
  padding-bottom: 1rem;
}

.usa-accordion--bordered .usa-accordion__heading {
  margin-bottom:0;
}

.usa-accordion__heading,
.usa-prose .usa-accordion__heading {
  font-size:1.06471rem;
  line-height:0.93923;
  margin:0;
  width:100%;
}

.usa-accordion__heading:not(:first-child),
.usa-prose .usa-accordion__heading:not(:first-child) {
  margin-top:0.5rem;
}

.usa-accordion__content{
  background-color:white;
  margin-top:0;
  overflow:auto;
  padding: 1rem 1.25rem calc(1rem - 0.25rem) 1.25rem;

  > * {
    max-width: 77ch;
  }

  li {
    margin: 1em 0;
  }
}

.usa-accordion__content > *:first-child {
  margin-top:0;
}

.usa-accordion__content > *:last-child {
  margin-bottom:0;
}

.usa-accordion__content[aria-hidden=true] {
  display:none;
}

.usa-accordion__button {
  border:0;
  border-radius:0;
  -webkit-box-shadow: none;
          box-shadow: none;
  font-weight: normal;
  margin: 0;
  text-align: left;
  background-image: url(/assets/img/minus.svg), -webkit-gradient(linear, left top, left bottom, from(transparent), to(transparent));
  background-image: url(/assets/img/minus.svg), linear-gradient(transparent, transparent);
  background-repeat: no-repeat;
  background-color: $color-warn-lightest;
  background-position: right 1.25rem center;
  background-size: 1rem;
  color: $color-primary-darkest;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  padding: 1rem 3.5rem 1rem 1.25rem;
  text-decoration: none;
  width: 100%;
}

.usa-accordion__button:hover {
  color: $color-primary-darkest;
}

.usa-accordion__button:active {
  color: $color-primary-darkest;
}

.usa-accordion__button:visited {
  color: $color-primary-darkest;
}

.usa-accordion__button:hover,
.usa-accordion__button:active {
  background-color:transparent;
  -webkit-box-shadow:none;
          box-shadow:none;
  text-decoration:underline;
}

.usa-accordion__button:hover {
  background-color: $color-warn-lighter;
  color: $color-gray-dark;
  text-decoration: none;
}

.usa-accordion__button[aria-expanded=false] {
  background-image: url(/assets/img/plus.svg), -webkit-gradient(linear, left top, left bottom, from(transparent), to(transparent));
  background-image: url(/assets/img/plus.svg), linear-gradient(transparent, transparent);
  background-repeat: no-repeat;
  background-size: 1rem;
}