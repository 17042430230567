/* stylelint-disable declaration-no-important */
@import '@cmsgov/design-system-support/src/settings/index';

.ds-u-text-decoration--underline {
  text-decoration: underline !important;
}

.ds-u-text-decoration--line-through {
  text-decoration: line-through !important;
}

.ds-u-text-decoration--none {
  text-decoration: none !important;
}
