@import '@cmsgov/design-system-support/src/index';

.ds-c-month-picker {
  max-width: $input-max-width;
}

.ds-c-month-picker__months {
  max-width: 400px;
}

.ds-c-month-picker__month {
  width: 100px;
}

.ds-c-month-picker__buttons {
  max-width: 400px;
}
