// These variables apply to form fields (input, select, textarea, button)
$input-line-height: 1.3 !default;
$input-border-width: 2px !default;
$input-small-width: 6em !default;
$input-medium-width: 12em !default;
$input-max-width: 460px !default;
$input-padding: $spacer-1 !default;
$input-border-radius: $border-radius !default;
$input-border-color: $color-base !default;
$input-border-color-inverse: $color-black !default;

$choice-checked-background-color: $color-primary !default;
$choice-border-color: $color-base !default;
$choice-border-color-inverse: $color-white !default;
$choice-border-width: 2px !default;
$choice-size: $spacer-4 !default;
$choice-size-small: 20px !default;

$button-border-width: 1px !default;
$button-primary-bg: $color-primary !default;
$button-primary-bg--hover: $color-primary-darker !default;
$button-primary-bg--active: $color-primary-darkest !default;
