// Paths
$font-path: '../fonts' !default;
$image-path: '../images' !default;

// Override these variables to change what CSS rulesets get outputted when
// the Sass is transpiled to CSS
$ds-include-base-html-rulesets: true !default;

// Set $ds-include-focus-styles to enable the beta CMSDS focus styles. These styles can be further customized via the `$focus-color` variable and `focus-text` mixin
$ds-include-focus-styles: true !default;
