/* stylelint-disable declaration-no-important */
@import '@cmsgov/design-system-support/src/settings/index';

.ds-u-font-style--normal {
  font-style: normal !important;
}

.ds-u-font-style--italic {
  font-style: italic !important;
}
