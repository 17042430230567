/* stylelint-disable declaration-no-important */
@import '@cmsgov/design-system-support/src/settings/index';

.ds-u-text-transform--uppercase {
  text-transform: uppercase !important;
}

.ds-u-text-transform--lowercase {
  text-transform: lowercase !important;
}

.ds-u-text-transform--capitalize {
  text-transform: capitalize !important;
}
