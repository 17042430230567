@import '@cmsgov/design-system-support/src/settings/index';

$step-list-number-size: 32px !default;
$step-list-number-size-mobile: 24px !default;
$step-list-number-font-size: 20px !default;
$step-list-number-font-size-mobile: $small-font-size !default;
$step-list-number-border-size: 2px !default;
$step-list-number-margin: 10px !default;
$step-list-padding-h: 0 !default;
$step-list-padding-left: $step-list-padding-h + $step-list-number-margin + $step-list-number-size;
$step-list-breakpoint: $width-md !default;
$step-margin: $spacer-2 !default;
$step-border-width: 1px !default;
$step-button-height: 38px !default;
$current-step-color: $color-primary !default;

.ds-c-step-list {
  list-style-type: none;
  margin: 0;
  padding: 0 $step-list-padding-h;
  padding-left: $step-list-padding-left;
}

.ds-c-step {
  color: $color-muted;
  counter-increment: step-counter;
  margin-bottom: $step-margin;
  padding-bottom: $step-margin;
  position: relative;

  &::before {
    border: $step-list-number-border-size solid $color-muted;
    border-radius: 50%;
    box-sizing: border-box;
    color: $color-muted;
    content: counter(step-counter);
    display: block;
    font-size: $step-list-number-font-size-mobile;
    height: $step-list-number-size-mobile;
    left: -($step-list-number-margin + $step-list-number-size-mobile);
    line-height: $step-list-number-size-mobile - $step-list-number-border-size * 2;
    position: absolute;
    text-align: center;
    top: ($step-button-height - $step-list-number-size-mobile) / 2;
    width: $step-list-number-size-mobile;

    @media (min-width: $step-list-breakpoint) {
      font-size: $step-list-number-font-size;
      height: $step-list-number-size;
      left: -($step-list-number-margin + $step-list-number-size);
      line-height: $step-list-number-size - $step-list-number-border-size * 2;
      padding-left: 1px;
      top: ($step-button-height - $step-list-number-size) / 2;
      width: $step-list-number-size;
    }
  }

  &::after {
    border-bottom: $step-border-width solid $border-color;
    bottom: 0;
    content: '';
    display: block;
    height: 1px;
    left: -$step-list-padding-left;
    position: absolute;
    right: -$step-list-padding-h;
  }

  @media (min-width: $step-list-breakpoint) {
    display: flex;
    justify-content: space-between;
  }
}

.ds-c-step--current,
.ds-c-step--completed {
  padding-bottom: $step-margin;

  &::before {
    border: 0 none;
    color: $color-white;
    line-height: $step-list-number-size-mobile;

    @media (min-width: $step-list-breakpoint) {
      line-height: $step-list-number-size;
    }
  }
}

.ds-c-step--current {
  .ds-c-step__heading {
    color: $current-step-color;
  }

  &::before {
    background: $current-step-color;
  }
}

.ds-c-step--completed {
  .ds-c-step__heading {
    color: $color-base;
  }

  .ds-c-step__substeps {
    color: $color-base;
  }

  &::before {
    background: $color-base;
  }
}

.ds-c-step__content {
  @media (min-width: $step-list-breakpoint) {
    padding-right: $spacer-2;
  }
}

.ds-c-step__heading {
  font-size: $h5-font-size;
  line-height: $step-button-height;
  margin: 0;

  @media (min-width: $step-list-breakpoint) {
    font-size: $h3-font-size;
  }
}

.ds-c-step__actions {
  font-size: $small-font-size;
  margin-left: auto;

  .ds-c-step__content--with-content + & {
    margin-top: $spacer-2;

    @media (min-width: $step-list-breakpoint) {
      margin-top: 0;
    }
  }

  .ds-c-step--completed & a {
    font-weight: $font-normal;
  }

  @media (min-width: $step-list-breakpoint) {
    font-size: $base-font-size;
    text-align: right;
  }
}

.ds-c-step__completed-text {
  display: inline-block;
  line-height: $step-button-height - 12px;
  margin-right: $spacer-2;

  &::before {
    background-image: url('#{$image-path}/checkmark-green.svg');
    background-size: contain;
    box-sizing: border-box;
    content: '';
    display: inline-block;
    height: 12px;
    margin-right: $spacer-1;
    width: 16px;
  }

  @media (min-width: $step-list-breakpoint) {
    display: block;
    margin-right: 0;
    padding-top: 6px;
    white-space: nowrap;
  }
}

.ds-c-step__description {
  font-size: $small-font-size;
  margin-bottom: $spacer-1;

  .ds-c-step--current &,
  .ds-c-step--completed & {
    margin-top: $spacer-1;
  }

  @media (min-width: $step-list-breakpoint) {
    font-size: $base-font-size;
  }
}

.ds-c-step__substeps {
  list-style-type: none;
  margin: 0;
  padding-left: $spacer-5;
}

.ds-c-substep {
  display: block;
  font-size: $small-font-size;

  &::before {
    content: '\2022';
    left: -$spacer-3;
    position: relative;
  }
}

.ds-c-substep__heading {
  display: inline-block;
  margin-right: $spacer-1;
}

.ds-c-substep__edit {
  @media (min-width: $step-list-breakpoint) {
    position: absolute;
    right: 0;
  }
}
