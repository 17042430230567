/* stylelint-disable declaration-no-important */
@import '@cmsgov/design-system-support/src/index';

@for $i from 1 through length($spacers) {
  $size: #{nth($spacers, $i)};
  $scale: #{$i - 1};

  // Example: ds-u-margin--0
  .ds-u-margin--#{$scale} {
    // Example: margin: 0 !important;
    margin: #{$size} !important;
  }

  .ds-u-margin-top--#{$scale} {
    margin-top: #{$size} !important;
  }

  .ds-u-margin-right--#{$scale} {
    margin-right: #{$size} !important;
  }

  .ds-u-margin-bottom--#{$scale} {
    margin-bottom: #{$size} !important;
  }

  .ds-u-margin-left--#{$scale} {
    margin-left: #{$size} !important;
  }

  .ds-u-margin-x--#{$scale} {
    margin-left: #{$size} !important;
    margin-right: #{$size} !important;
  }

  .ds-u-margin-y--#{$scale} {
    margin-bottom: #{$size} !important;
    margin-top: #{$size} !important;
  }
}

.ds-u-margin--auto {
  margin: auto !important;
}

.ds-u-margin-top--auto {
  margin-top: auto !important;
}

.ds-u-margin-right--auto {
  margin-right: auto !important;
}

.ds-u-margin-bottom--auto {
  margin-bottom: auto !important;
}

.ds-u-margin-left--auto {
  margin-left: auto !important;
}

.ds-u-margin-x--auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.ds-u-margin-y--auto {
  margin-bottom: auto !important;
  margin-top: auto !important;
}

@each $breakpoint in map-keys($breakpoints) {
  $value: map-get($breakpoints, $breakpoint);

  @media (min-width: $value) {
    @for $i from 1 through length($spacers) {
      $size: #{nth($spacers, $i)};
      $scale: #{$i - 1};

      // Example: ds-u-sm-margin--0
      .ds-u-#{$breakpoint}-margin--#{$scale} {
        // Example: margin: 0 !important;
        margin: #{$size} !important;
      }

      .ds-u-#{$breakpoint}-margin-top--#{$scale} {
        margin-top: #{$size} !important;
      }

      .ds-u-#{$breakpoint}-margin-right--#{$scale} {
        margin-right: #{$size} !important;
      }

      .ds-u-#{$breakpoint}-margin-bottom--#{$scale} {
        margin-bottom: #{$size} !important;
      }

      .ds-u-#{$breakpoint}-margin-left--#{$scale} {
        margin-left: #{$size} !important;
      }

      .ds-u-#{$breakpoint}-margin-x--#{$scale} {
        margin-left: #{$size} !important;
        margin-right: #{$size} !important;
      }

      .ds-u-#{$breakpoint}-margin-y--#{$scale} {
        margin-bottom: #{$size} !important;
        margin-top: #{$size} !important;
      }
    }

    .ds-u-#{$breakpoint}-margin--auto {
      margin: auto !important;
    }

    .ds-u-#{$breakpoint}-margin-top--auto {
      margin-top: auto !important;
    }

    .ds-u-#{$breakpoint}-margin-right--auto {
      margin-right: auto !important;
    }

    .ds-u-#{$breakpoint}-margin-bottom--auto {
      margin-bottom: auto !important;
    }

    .ds-u-#{$breakpoint}-margin-left--auto {
      margin-left: auto !important;
    }

    .ds-u-#{$breakpoint}-margin-x--auto {
      margin-left: auto !important;
      margin-right: auto !important;
    }

    .ds-u-#{$breakpoint}-margin-y--auto {
      margin-bottom: auto !important;
      margin-top: auto !important;
    }
  }
}
