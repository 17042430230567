@mixin clearfix {
  &::before {
    content: '';
    display: table;
  }

  &::after {
    clear: both;
    content: '';
    display: table;
  }
}

@mixin flexbox-col($size) {
  flex: 0 0 percentage($size / $grid-columns);
  max-width: percentage($size / $grid-columns); // IE10+ and Firefox
}

@mixin equal-width-flexbox-col() {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

@mixin auto-width-flexbox-col() {
  flex: 0 0 auto;
  max-width: none;
  width: auto;
}
