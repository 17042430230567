.billboard {
  background: $color-primary-darkest url(/assets/img/bottom-heart.svg) no-repeat 156px center;
  background-size: 130% 130%;
  color: $color-white;
  padding: $spacer-7 0;
}

.billboard__heading {
  color: $color-white;
  font-size: $h1-font-size;
  margin-top: 0;
}