@import 'styles.scss';

.api-pattern {
    background-image: url(../img/iconsbcda.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top right;
    width: 100%;
}

.blueberry-lime-gradient-background {
background: linear-gradient(30deg, #339392, #339392 73%, #62C5C4);
}

.solid-container {
    width: 60%;
    background-color: #339392;
}

article h2{
  display: inline-block;
  position: relative;
}

.faq-question{
    color: #FAD980
}

.subnav .blueberry-lime-link:active:before,
.subnav .blueberry-lime-link:focus:before,
.subnav .blueberry-lime-link:hover:before {
    background: linear-gradient(30deg, #34EFA4 10%, #087BAE 90%)
}

.logo-link-container{
  color: #0071BC
}

.ds-c-desktop-nav .desktop-nav-items a, .mobile-nav-items a{
  color: #0071BC
}

.bcda_callout {
    background-color: #F5F5F5;
    padding: 20px;
}

.no_list{
  list-style: none;
}

.button p{
  text-align: center;
  width:100%;
  margin: auto;
}

.button p a{
  color:white;
  text-decoration: none;
  width:60%;
  border-radius:3px;
  padding: 10px;
  background-color: #0071BC;
  display:block;
  margin:auto;
}

#google_logo {
    max-height: 60px;
    float: right;
    margin-top: -15px;
    padding-bottom: 25px;
  }

.ds-text--lead {
    font-size: 24px;
}

@media only screen and (max-width: 1090px){
  .api-pattern {
    background:none;
    background-color: #339392;
  }
  .solid-container {
      width: 100%;
  }

}

@media only screen and (min-width: 1960px){
    .solid-container {
        width: 80%;
    }
    .blueberry-lime-gradient-background {
        background: linear-gradient(30deg, #339392, #339392 83%, #62C5C4);
    }

}

.topdrop {
    display: inline-block;
    margin-left: -10px;
}

.dropdown {
    position: relative;
    display: inline-block;
    margin-right:20px;
}

.topmenu ,
.topmenu:visited {
    position:relative;
    display: inline-block;
    appearance: none;
    background-color: #339392;
    border-radius: 3px 3px 0px 0px;
    cursor: pointer;
    font-family: "Open Sans", Helvetica, sans-serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 1.3;
    text-align: center;
    text-decoration: none;
    transition: all .3s ease;
    color: #fff;
    padding: 5px 10px 8px 10px;
}

.topmenu:active,
.topmenu:hover,
.topmenu.active {
    background-color: #fff;
    color: #339392;
}

@media only screen and (max-width: 1023px){
  .topmenu {
    padding: 8px 10px;
    margin: 5px;
  }
  .topmenu:active,
  .topmenu:focus,
  .topmenu:hover,
  .topmenu.active {
    border-radius: 3px;
  }
  .benefit-prop img {
    max-width: 50%;
    display: block;
    margin: 0 auto;
  }
  .api-card {
    min-height: 500px;
    width: 100%;
    img {
      max-width: 50%;
      display: block;
      margin: 0 auto;
      min-height: 250px;
    }
  }
}

.content_buffer {
    margin-top: 56px;
    margin-left: auto;
    margin-right: auto;
}

.highlight {
    background-color: #fff;
    color: #326867;
}

#main p img.ug-img {
  width: 100%;
  max-width: 500px;
}

.accordion {
    background-color: #eee;
    color: #444;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    text-align: left;
    border: none;
    outline: none;
    transition: 0.4s;
}

.accordion_active {
    border: 2px #fff solid;
    background-color: #fff;
}

.acc_content {
    padding: 0 18px;
    background-color: white;
    display: none;
    overflow: hidden;
}

.accordion:after {
    content: '\02795';
    font-size: 13px;
    color: #777;
    float: right;
    margin-left: 5px;
}

.accordion_active:after {
    content: "\2796";
}

a {
    overflow-wrap: break-word;
    word-wrap: break-word;
}

code {
    overflow-wrap: break-word;
    word-wrap: break-word;
}

pre {
    color: #203341;
}

.slim_ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

/* Visual focus on the all code elements. */
code[tabindex="0"]:focus {
    outline: 1px dotted #567482;
}

/* Visual focus on the pre element, which contain code elements. */
pre:focus-within {
    outline: 1px dotted #567482;
}

/* Cancels visual focus on code elements inside pre element so there isn't a double outline. */
pre:focus-within code[tabindex="0"]:focus {
    outline: 0 none;
}

.ds-c-link:focus, .ds-c-link:focus:visited, a:focus, a:focus:visited,
.ds-c-desktop-nav .desktop-nav-items a:focus, .mobile-nav-items a:focus,
a.ds-c-button.ds-u-margin-top--4:focus, button.accordion:focus, button.accordion:hover,
.button p a:focus {
    // background-color: #68C1BF;
    color: #212121;
}

.hex_background {
    background-image: url("/assets/img/hex_background.svg");
}

table, th, td {
    border: 1px solid black;
    border-collapse: collapse;
  }

th, td {
    padding: 15px;
    text-align: left;
    vertical-align: top;
}

th { background-color: #D3D3D3; }

.cex {
    padding: 0px;
}

$animation-ease-in-out-expo: cubic-bezier(1, 0, 0, 1) !default;
$animation-speed-base: 1 !default;
$animation-speed-1: 0.25s !default;
$animation-speed-2: 0.3s !default;
$animation-speed-3: 0.5s !default;
$animation-speed-4: 0.8s !default;
$color-white: #ffffff !default;
$color-black: #000000 !default;
$color-transparent: #ffffff00 !default;
$color-transparent-black-alpha50: #00000080 !default;
$color-transparent-black-alpha25: #00000040 !default;
$color-transparent-white-alpha50: #ffffff80 !default;
$color-transparent-white-alpha25: #ffffff40 !default;
$color-background: #ffffff !default;
$color-background-dialog: #ffffff !default;
$color-background-dialog-mask: #00000080 !default;
$color-background-inverse: #00395e !default;
$color-base: #262626 !default;
$color-base-inverse: #ffffff !default;
$color-border: #d9d9d9 !default;
$color-border-dark: #0f1e38 !default;
$color-border-inverse: #ffffff !default;
$color-coolblue: #045d83 !default;
$color-coolblue-light: #046791 !default;
$color-coolblue-lighter: #82b3c8 !default;
$color-coolblue-lightest: #e6f0f4 !default;
$color-error: #e31c3d !default;
$color-error-dark: #cc1937 !default;
$color-error-darker: #9f142b !default;
$color-error-darkest: #720e1f !default;
$color-error-light: #f18e9e !default;
$color-error-lighter: #f7bbc5 !default;
$color-error-lightest: #fce8ec !default;
$color-focus: #3e94cf !default;
$color-focus-border-inverse: #7c6210 !default;
$color-focus-dark: #bd13b8 !default;
$color-focus-inverse: #02bfe7 !default;
$color-focus-light: #ffffff !default;
$color-focus-shadow: #262626 !default;
$color-focus-shadow-inverse: #262626 !default;
$color-focus-shadow-link: #262626 !default;
$color-focus-shadow-link-inverse: #7c6210 !default;
$color-gold: #f8c41f !default;
$color-gold-dark: #dfb01c !default;
$color-gold-darker: #ae8916 !default;
$color-gold-darkest: #7c6210 !default;
$color-gold-light: #f9ca35 !default;
$color-gold-lighter: #fce28f !default;
$color-gold-lightest: #fef9e9 !default;
$color-gray: #5a5a5a !default;
$color-gray-cool-light: #e6f1f8 !default;
$color-gray-dark: #404040 !default;
$color-gray-light: #a6a6a6 !default;
$color-gray-lighter: #d9d9d9 !default;
$color-gray-lightest: #f2f2f2 !default;
$color-gray-medium: #737373 !default;
$color-gray-warm-dark: #404040 !default;
$color-gray-warm-light: #f2f2f2 !default;
$color-green: #12890e !default;
$color-green-dark: #107b0d !default;
$color-green-darker: #0d600a !default;
$color-green-darkest: #094507 !default;
$color-green-light: #2a9526 !default;
$color-green-lighter: #89c487 !default;
$color-green-lightest: #e7f3e7 !default;
$color-muted: #5a5a5a !default;
$color-muted-inverse: #e9ecf1 !default;
$color-primary: #0071bc !default;
$color-primary-darker: #004f84 !default;
$color-primary-darkest: #00395e !default;
$color-primary-alt: #02bfe7 !default;
$color-primary-alt-dark: #02acd0 !default;
$color-primary-alt-darkest: #016074 !default;
$color-primary-alt-light: #4ed2ee !default;
$color-primary-alt-lightest: #e6f9fd !default;
$color-secondary: #02bfe7 !default;
$color-secondary-dark: #02acd0 !default;
$color-secondary-darker: #0186a2 !default;
$color-secondary-darkest: #016074 !default;
$color-secondary-light: #4ed2ee !default;
$color-secondary-lighter: #b3ecf8 !default;
$color-secondary-lightest: #e6f9fd !default;
$color-red: #e31c3d !default;
$color-red-dark: #cc1937 !default;
$color-red-darker: #9f142b !default;
$color-red-darkest: #720e1f !default;
$color-red-light: #f18e9e !default;
$color-red-lighter: #f7bbc5 !default;
$color-red-lightest: #fce8ec !default;
$color-success: #12890e !default;
$color-success-dark: #107b0d !default;
$color-success-darker: #0d600a !default;
$color-success-darkest: #094507 !default;
$color-success-light: #2a9526 !default;
$color-success-lighter: #89c487 !default;
$color-success-lightest: #e7f3e7 !default;
$color-warn: #f8c41f !default;
$color-warn-dark: #dfb01c !default;
$color-warn-darker: #ae8916 !default;
$color-warn-darkest: #7c6210 !default;
$color-warn-light: #f9ca35 !default;
$color-warn-lighter: #fce28f !default;
$color-warn-lightest: #fef9e9 !default;
$color-visited: #4c2c92 !default;
$font-sans: 'Open Sans', Helvetica, sans-serif !default;
$font-serif: Bitter, Georgia, serif !default;
$font-family-open-sans: 'Open Sans', Helvetica, sans-serif !default;
$font-family-rubik: 'Rubik', sans-serif !default;
$font-family-montserrat: 'Montserrat', sans-serif !default;
$font-family-bitter: Bitter, Georgia, serif !default;
$font-size-base: 16px !default;
$font-size-sm: 14px !default;
$font-size-md: 16px !default;
$font-size-lg: 18px !default;
$font-size-xl: 21px !default;
$font-size-2xl: 24px !default;
$font-size-3xl: 36px !default;
$font-size-4xl: 48px !default;
$font-size-5xl: 60px !default;
$font-line-height-reset: 1 !default;
$font-line-height-base: 1.5 !default;
$font-line-height-heading: 1.3 !default;
$font-line-height-lead: 1.7 !default;
$font-weight-normal: 400 !default;
$font-weight-bold: 700 !default;
$font-weight-semibold: 600 !default;
$article-max-width: 600px !default;
$grid-columns: 12 !default;
$grid-gutter-width: 32px !default;
$grid-form-gutter-width: 16px !default;
$lead-max-width: 77rem !default;
$nav-width: 951px !default;
$site-margins: 3rem !default;
$site-margins-mobile: 1.5rem !default;
$site-max-width: 1040px !default;
$text-max-width: 53rem !default;
$measure-narrow: 45ex !default;
$measure-base: 65ex !default;
$measure-wide: 80ex !default;
$media-width-xs: 0px !default;
$media-width-sm: 544px !default;
$media-width-md: 768px !default;
$media-width-lg: 1024px !default;
$media-width-xl: 1280px !default;
$radius-circle: 100% !default;
$radius-default: 3px !default;
$radius-large: 8px !default;
$radius-medium: 4px !default;
$radius-pill: 9999px !default;
$radius-small: 2px !default;
$shadow-focus: inset 0 0 0 1px #262626 !default;
$shadow-focus-inverse: inset 0 0 0 1px #262626 !default;
$shadow-focus-link: 0 3px #262626 !default;
$shadow-focus-link-inverse: 0 3px #7c6210 !default;
$shadow-base-offset-x: 2px !default;
$shadow-base-offset-y: 2px !default;
$shadow-base-blur-radius: 4px !default;
$shadow-base-color: #00000040 !default;
$shadow-base: 2px 2px 4px !default;
$spacer-1: 8px !default;
$spacer-2: 16px !default;
$spacer-3: 24px !default;
$spacer-4: 32px !default;
$spacer-5: 40px !default;
$spacer-6: 48px !default;
$spacer-7: 56px !default;
$spacer-none: 0px !default;
$spacer-half: 4px !default;
$usa-banner-mobile-close-size: 48px;
$usa-caret-icon-size: 10px;
$usa-banner-font-family: $font-sans;
$usa-banner__background-color: #f2f2f2 !default;
$usa-banner__color: #000000 !default;
$usa-banner-close__background-color: #a6a6a6 !default;
$usa-banner-action__color: #004f84 !default;
$usa-banner-lock-icon__color: #2a9526 !default;

.ds-c-icon-color--primary {
  color: #0071bc;
}

@mixin focus-styles {
  box-shadow: 0 0 0 3px $color-focus-light, 0 0 4px 6px $color-focus-dark;
  // Add support for Windows High Contrast Mode (WHCM)
  // The transparent color only shows when WHCM is triggered
  outline: 3px solid transparent;
  outline-offset: 3px;
}

@mixin focus-styles-position {
  position: relative;
  z-index: 100;
}

.ds-c-icon--arrow-up {
  transform: rotate(-90deg);
}

.ds-c-icon--arrow-down {
  transform: rotate(90deg);
}

@mixin focus-styles-link {
  background-color: $color-focus-light;
  box-shadow: none;
  color: initial;
  outline: 3px solid $color-focus-dark;
  outline-offset: 1px;
  text-decoration: underline;
}

.ds-c-usa-banner {
  background-color: $usa-banner__background-color;
  color: $usa-banner__color;
  font-family: $usa-banner-font-family;
  font-size: $font-size-base;
  padding: 0;
}

.ds-c-usa-banner__header {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  font-size: 12px;
  font-weight: $font-weight-normal;
  line-height: 1.2;
  min-height: $spacer-4;
  padding: $spacer-1 $spacer-2 $spacer-1 $spacer-2;
  position: relative;

  @media (min-width: $width-sm) {
    min-height: 0;
    padding-bottom: 4px;
    padding-top: 4px;
  }
}

.ds-c-usa-banner__header--mobile {
  padding: 0;

  .ds-c-usa-banner__button {
    display: flex;
    font-family: $usa-banner-font-family;
    padding: $spacer-1 $spacer-2 $spacer-1 $spacer-2;
    width: 100%;
  }

  &.ds-c-usa-banner__header--expanded {
    .ds-c-usa-banner__button {
      padding-bottom: 0;
    }
  }

  .ds-c-usa-banner__cta-wrapper {
    display: block;
  }
}

.ds-c-usa-banner__collapse-banner-container {
  align-items: center;
  background-color: $usa-banner-close__background-color;
  display: flex;
  height: $usa-banner-mobile-close-size;
  justify-content: center;
  margin-left: auto;
  margin-right: -$spacer-2;
  margin-top: -$spacer-1;
  width: $usa-banner-mobile-close-size;

  .ds-c-icon {
    height: $font-size-lg;
    width: $font-size-lg;
  }
}

.ds-c-usa-banner__header-flag {
  height: 11px;
  margin-right: $spacer-1;
  width: $spacer-2;
}

.ds-c-usa-banner__header-text {
  display: inline;
  margin: 0;
  span {
    margin-right: $spacer-1;
  }
}

.ds-c-usa-banner__header-action {
  color: $usa-banner-action__color;
  display: block;
  margin-bottom: 0;
  margin-top: 2px;
  text-decoration: underline;
}

.ds-c-usa-banner__button {
  background-color: transparent;
  border: 0;
  bottom: 0;
  box-shadow: none;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
  padding: 0;
  text-align: left;

  &:focus {
    @include focus-styles;
  }
}

.ds-c-usa-banner__button-text {
  @extend .ds-c-link;
}

.ds-c-usa-banner__content {
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  padding: 4px $spacer-2 $spacer-2 $spacer-1;

  @media (min-width: $width-sm) {
    padding-bottom: $spacer-3;
    padding-top: $spacer-3;
  }
}

.ds-c-usa-banner__guidance-container {
  display: flex;
  flex-direction: column;

  @media (min-width: $width-sm) {
    flex-flow: row nowrap;
  }
}

.ds-c-usa-banner__guidance {
  align-items: flex-start;
  display: flex;
  max-width: 64ex;
  padding: $spacer-2 12px 0 12px;

  @media (min-width: $width-sm) {
    padding: 0 $spacer-1;
    width: 50%;
  }
}

.ds-c-usa-banner__icon {
  flex-shrink: 0;
  height: $spacer-5;
  margin-right: $spacer-1;
  width: $spacer-5;

  &.ds-c-icon--lock-circle {
    color: $usa-banner-lock-icon__color;

    @media (-ms-high-contrast: active), (forced-colors: active) {
      color: WindowText;
    }
  }
}

.ds-c-usa-banner__lock-image {
  height: 1.5ex;
  vertical-align: inherit;
  width: calc(1.5ex * 52 / 64);

  path {
    fill: currentColor;
  }

  @media (-ms-high-contrast: active), (forced-colors: active) {
    path {
      fill: WindowText;
    }
  }
}

.ds-c-usa-banner__media-img {
  float: left;
  margin-right: $spacer-1;
}

.ds-c-usa-banner__media-body {
  margin: 0;
}

.ds-c-usa-banner__header--expanded {
  @media (min-width: $width-sm) {
    background-color: transparent;
    padding-right: 0;
    .ds-c-usa-banner__button-text::after {
      transform: rotate(180deg);
    }
  }

  .ds-c-usa-banner__header-action {
    display: none;
  }
}

.ds-c-usa-banner__action-icon {
  color: $usa-banner__color;
  height: $usa-caret-icon-size;
  width: $usa-caret-icon-size;
}