@import '@cmsgov/design-system-support/src/settings/index';

.ds-c-field {
  appearance: none;
  border: $input-border-width solid $input-border-color;
  border-radius: $input-border-radius;
  box-sizing: border-box; // ensure padding doesn't affect width
  color: $color-base;
  display: block;
  margin: $spacer-half 0;
  max-width: $input-max-width;
  outline: none;
  padding: $input-padding;
  width: 100%;

  &:disabled {
    background-color: $color-gray-lighter;
    border-color: $color-gray-light;
  }

  &:focus {
    @if $ds-include-focus-styles {
      border-radius: 0;
      box-shadow: $focus-shadow;
      outline: 3px solid $focus-color;
      outline-offset: 0;
    } @else {
      box-shadow: 0 0 3px $color-focus, 0 0 7px $color-focus;
    }
  }
}

.ds-c-field--small {
  max-width: $input-small-width;
}

.ds-c-field--medium {
  max-width: $input-medium-width;
}

.ds-c-field,
.ds-c-field-mask {
  font-family: $font-sans;
  font-size: $base-font-size;
  line-height: $input-line-height;
}

/*
Inverse theme
*/

.ds-c-field--inverse {
  border-color: $input-border-color-inverse;

  &:focus {
    @if $ds-include-focus-styles {
      box-shadow: $focus-shadow-inverse;
    } @else {
      box-shadow: 0 0 3px $color-focus-inverse, 0 0 7px $color-focus-inverse;
    }
  }
}

// State modifiers and message
// ==============================
.ds-c-field--error {
  border: 3px solid $color-error;
  @if $ds-include-focus-styles {
    &:focus {
      border-color: $color-base;
      box-shadow: none;
    }
  }
}

.ds-c-field--success {
  border: 3px solid $color-success-light;
  @if $ds-include-focus-styles {
    &:focus {
      border-color: $color-base;
      box-shadow: none;
    }
  }
}
