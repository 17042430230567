@import '@cmsgov/design-system-support/src/settings/index';

.ds-c-field--month,
.ds-c-field--day,
.ds-c-field--year {
  box-sizing: content-box; // allows us to set width based on # of characters
}

.ds-c-field--month,
.ds-c-field--day {
  width: 4ch; // provide additional spacing for browser UI
}

.ds-c-field--year {
  width: 6ch; // provide additional spacing for browser UI
}

.ds-c-datefield__separator {
  font-size: $h2-font-size;
  line-height: $input-line-height * $base-font-size; // vertical dimensions should match adjacent inputs
  margin: ($input-border-width + $spacer-half) 0;
  padding: $input-padding 0;
}
