/* stylelint-disable declaration-no-important */
@import '@cmsgov/design-system-support/src/tools/index';

.ds-u-overflow--hidden {
  overflow: hidden !important;
}

.ds-u-overflow--scroll {
  overflow: scroll !important;
}

.ds-u-overflow--auto {
  overflow: auto !important;
}
