/* stylelint-disable declaration-no-important */
@import '@cmsgov/design-system-support/src/settings/index';

.ds-u-font-size--small {
  font-size: $small-font-size !important;
}

.ds-u-font-size--base {
  font-size: $base-font-size !important;
}

.ds-u-font-size--lead {
  font-size: $lead-font-size !important;
}

.ds-u-font-size--display {
  font-size: $display-font-size !important;
}

.ds-u-font-size--title {
  font-size: $title-font-size !important;
}

.ds-u-font-size--h1 {
  font-size: $h1-font-size !important;
}

.ds-u-font-size--h2 {
  font-size: $h2-font-size !important;
}

.ds-u-font-size--h3 {
  font-size: $h3-font-size !important;
}

.ds-u-font-size--h4 {
  font-size: $h4-font-size !important;
}

@each $breakpoint in map-keys($breakpoints) {
  $value: map-get($breakpoints, $breakpoint);

  @media (min-width: $value) {
    .ds-u-#{$breakpoint}-font-size--small {
      font-size: $small-font-size !important;
    }

    .ds-u-#{$breakpoint}-font-size--base {
      font-size: $base-font-size !important;
    }

    .ds-u-#{$breakpoint}-font-size--lead {
      font-size: $lead-font-size !important;
    }

    .ds-u-#{$breakpoint}-font-size--display {
      font-size: $display-font-size !important;
    }

    .ds-u-#{$breakpoint}-font-size--title {
      font-size: $title-font-size !important;
    }

    .ds-u-#{$breakpoint}-font-size--h1 {
      font-size: $h1-font-size !important;
    }

    .ds-u-#{$breakpoint}-font-size--h2 {
      font-size: $h2-font-size !important;
    }

    .ds-u-#{$breakpoint}-font-size--h3 {
      font-size: $h3-font-size !important;
    }

    .ds-u-#{$breakpoint}-font-size--h4 {
      font-size: $h4-font-size !important;
    }
  }
}
