/*
Use a container to center align your page and constrain its width so it doesn't
span too wide.
*/
.ds-l-container {
  margin-left: auto;
  margin-right: auto;
  max-width: $site-max-width;
  padding-left: $grid-gutter-width / 2;
  padding-right: $grid-gutter-width / 2;

  @media (min-width: $width-sm) {
    padding-left: $grid-gutter-width;
    padding-right: $grid-gutter-width;
  }
}
