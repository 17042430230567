/*! Design System - Core v */
// Sass variables, mixins, and functions
@import '@cmsgov/design-system-support/src/index';

// Generics: Far reaching selectors
@import 'generics/index';

// Base: Element selectors (though namespaced)
@import 'base/index';

// Components: Designed UI units
@import 'components/index';

// Utilities: Overrides, helpers; These trump the above rules.
@import 'utilities/index';
