@import '@cmsgov/design-system-support/src/settings/index';

.ds-c-autocomplete {
  max-width: $input-max-width;
  position: relative;
}

.ds-c-autocomplete__list {
  background-color: $color-white;
  box-sizing: border-box;
  max-height: ($base-font-size + $spacer-2) * 10;
  overflow-y: auto;
  position: absolute;
  width: 100%;
  z-index: $z-dialog;
}

.ds-c-autocomplete__list-item {
  color: $color-primary;
  padding: $spacer-1;
}

.ds-c-autocomplete__list-item--active {
  background-color: $color-primary-alt-darkest;
  color: $color-white;
}

.ds-c-autocomplete__list-item--message {
  color: $color-muted;
  padding: $spacer-1;
}
