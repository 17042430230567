// Typography

// Removing the !default from $em-base so we are not inheriting that
// value from Bourbon.
$em-base: 10px;

// USWDS uses rem units for their font sizes, however this relies on
// the root element (typically <html>) to have its font size set to
// 10px. Since styling a root element goes against the guiding principles
// of this design system, we use px units instead.
$base-font-size: 16px !default;
$small-font-size: 14px !default;
$lead-font-size: 18px !default;

$h1-font-size: 36px !default;
$h1-mobile-font-size: 30px !default;
$h2-font-size: 24px !default;
$h3-font-size: 21px !default;
$h4-font-size: 18px !default;
$h5-font-size: $base-font-size !default;
$h6-font-size: $small-font-size !default;

$title-font-size: 48px !default;
$display-font-size: 60px !default;

$font-sans: 'Open Sans', Helvetica, sans-serif !default;
$font-serif: Bitter, Georgia, serif !default;

$reset-line-height: 1 !default;
$base-line-height: 1.5 !default;
$heading-line-height: 1.3 !default;
$lead-line-height: 1.7 !default;

$font-normal: 400 !default;
$font-bold: 700 !default;
$font-semibold: 600;

$measure-narrow: 45ex;
$measure-base: 65ex;
$measure-wide: 80ex;
