.site-pills {
  list-style: none;
  margin: $spacer-2 $spacer-2 $spacer-2 0;
  padding: 0;
}

.site-pills__item {
  background-color: $color-primary-alt-lightest;
  border-radius: 50px;
  color: $color-primary-alt-darkest;
  display: inline-block;
  font-weight: $font-bold;
  font-size: 12px;
  margin: $spacer-1 / 2;
  padding: 1em 2em;
  text-transform: uppercase;

  @media (min-width: $width-md) {
    font-size: 14px;
  }

  @media (min-width: $width-lg) {
    font-size: $base-font-size;
  }

  &:first-child {
    margin-left: 0;
  }
}

.site-pills__item--small {
  align-items: center;
  display: inline-flex;
  font-size: 12px;
  margin: $spacer-1 / 4;
  padding: .5em 1em;
  text-transform: none;

  @media (min-width: $width-md) {
    font-size: 12px;
  }

  @media (min-width: $width-lg) {
    font-size: 12px;
  }
}

.site-pills__item--orange {
  background-color: $color-warn-alt-light;
  color: $color-warn-alt;
}

.site-pills__item--dark-blue {
  background-color: rgba($color-primary-alt-light, .7);
  color: $color-primary-alt-dark;
}

.site-pills__item--green {
  background-color: $color-success-alt-lightest;
  color: $color-success;
}

.site-pills__item--gray {
  background-color: $color-gray-lightest;
  color: $color-gray;

  .site-pills__item__button {
    color: rgba($color-gray, .5);
  }
}

.site-pills__item__button {
  color: rgba($color-primary-alt-darkest, .5);
  display: inline-block;
  margin: 5px 0 0 $spacer-1;
}