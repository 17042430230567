$multiple: 8px !default;

$spacers: (
  0,
  $multiple,
  $multiple * 2,
  $multiple * 3,
  $multiple * 4,
  $multiple * 5,
  $multiple * 6,
  $multiple * 7
) !default;

$spacer-1: nth($spacers, 2) !default; // 8px
$spacer-2: nth($spacers, 3) !default; // 16px
$spacer-3: nth($spacers, 4) !default; // 24px
$spacer-4: nth($spacers, 5) !default; // 32px
$spacer-5: nth($spacers, 6) !default; // 40px
$spacer-6: nth($spacers, 7) !default; // 48px
$spacer-7: nth($spacers, 8) !default; // 56px
$spacer-half: $spacer-1 / 2; // 4px

// Breakpoint widths
// INTERNAL: If these values change, the values used for the responsive previews
// in the documentation should also change.
$width-xs: 0 !default;
$width-sm: 544px !default;
$width-md: 768px !default;
$width-lg: 1024px !default;
$width-xl: 1280px !default;

// Breakpoints
$breakpoints: (
  sm: $width-sm,
  md: $width-md,
  lg: $width-lg,
  xl: $width-xl
) !default;

// Borders
$border-radius: 3px !default;
$border-radius-circle: 100%;
$border-radius-pill: 9999px;

// Grid
$grid-columns: 12 !default;
$grid-gutter-width: $spacer-4 !default;
$grid-form-gutter-width: $spacer-2 !default;

// Z-index
$z-dialog: 1000 !default;

// Site variables
$site-margins: 3rem !default;
$site-margins-mobile: 1.5rem !default;
$site-max-width: 1040px !default;
$text-max-width: 53rem !default;
$lead-max-width: 77rem !default;
$article-max-width: 600px !default;
$nav-width: 951px !default;

// 44 x 44 pixels hit target following Apple iOS Human Interface Guidelines
$hit-area: 4.4rem !default;
