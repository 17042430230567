@mixin focus-text {
  background-color: $focus-color;
  box-shadow: $focus-shadow-link;
  color: $color-base;
  outline: 3px solid transparent;
  text-decoration: none;
}

@mixin focus-text-inverse {
  background-color: $focus-color-inverse;
  box-shadow: $focus-shadow-link-inverse;
  color: $color-base;
  outline: 3px solid transparent;
  text-decoration: none;
}
