@import '@cmsgov/design-system-support/src/settings/index';

.ds-c-table {
  border-collapse: collapse;
  border-spacing: 0;
  margin: 0;

  td {
    background-color: $color-white;
  }

  th,
  thead td {
    background-color: $color-gray-lightest;
    text-align: left;
  }

  tbody th {
    font-weight: $font-normal;
  }

  th,
  td {
    border: 1px solid $color-gray;
    padding: $spacer-2;
  }
}

.ds-c-table--borderless {
  thead {
    tr {
      background-color: transparent;
    }

    th {
      border-top: 0;
    }
  }

  th,
  td {
    border-left: 0;
    border-right: 0;
  }
}

.ds-c-table__caption {
  font-weight: $font-bold;
  margin-bottom: $spacer-1;
  text-align: left;
}
