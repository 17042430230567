.dashboard-section-header {
  align-items: center;
  border-bottom: 1px solid $color-gray-light;
  display: flex;
  justify-content: space-between;
  margin: $spacer-5 0 $spacer-2;
  padding-bottom: $spacer-1;
}

.dashboard-section-heading {
  color: $color-primary-darkest;
  font-weight: $font-normal;
  margin: 0;
  padding: 0;
}