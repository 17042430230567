/* stylelint-disable declaration-no-important */
@import '@cmsgov/design-system-support/src/settings/index';

.ds-u-measure--narrow {
  max-width: $measure-narrow !important;
}

.ds-u-measure--base {
  max-width: $measure-base !important;
}

.ds-u-measure--wide {
  max-width: $measure-wide !important;
}
