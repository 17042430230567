@import '@cmsgov/design-system-support/src/index';

@keyframes slideInHelpDrawer {
  from {
    opacity: 0;
    transform: translate3d(200px, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.ds-c-help-drawer {
  background: $color-background;
  bottom: 0;
  box-shadow: -2px 0 0 $border-color;
  display: flex; // Flex layout helps ds-c-help-drawer__body take up full height regardless of ds-c-help-drawer__header height
  flex-direction: column;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 10;

  @media (min-width: $width-md) {
    animation: slideInHelpDrawer $animation-speed-2 ease-in-out both; // slide in from the right
    max-width: 33%; // this equates to 4 grid columns
  }

  @media (min-width: $width-xl) {
    max-width: $measure-base;
  }
}

.ds-c-help-drawer__close-button {
  flex-shrink: 0;
}

.ds-c-help-drawer__header {
  flex-shrink: 0;
}
@if $ds-include-focus-styles {
  // This element is focusable via a tabindex attribute.
  .ds-c-help-drawer__header h3:focus {
    @include focus-text;
  }
}

.ds-c-help-drawer__body {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: scroll;
}

.ds-c-help-drawer__content {
  flex-grow: 1;
  flex-shrink: 0;

  p:first-child {
    // Prevent too much space at the top of the body area
    margin-top: 0;
  }
}

.ds-c-help-drawer__footer {
  flex-shrink: 0;
}
