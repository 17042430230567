/* stylelint-disable declaration-no-important */
@import '@cmsgov/design-system-support/src/settings/index';

.ds-u-sans {
  font-family: $font-sans !important;
}

.ds-u-serif {
  font-family: $font-serif !important;
}
